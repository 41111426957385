import React, { useState, useEffect } from 'react';
import { Button, TextField, Box, Snackbar, Alert } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import SaveIcon from '@mui/icons-material/Save';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import * as XLSX from 'xlsx';
import axios from 'axios';
import ColorPicker from './ColorPicker';
import './recorridoBuses.css';

const RecorridoDeBuses = () => {
  const [data, setData] = useState([]);
  const [linea, setLinea] = useState('');
  const [excelCargado, setExcelCargado] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertSeverity, setAlertSeverity] = useState('success');
  const [map, setMap] = useState(null);
  const [polyline, setPolyline] = useState(null);
  const [polylineColor, setPolylineColor] = useState('#FF0000');

  useEffect(() => {
    if (window.google) {
      initMap();
    } else {
      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyD1Nl9yulU0jgl6VRGkK89neIalMrblrws&signed_in=true&callback=initMap`;
      script.async = true;
      document.body.appendChild(script);
      window.initMap = initMap;
    }
  }, []);

  const initMap = () => {
    const mapInstance = new window.google.maps.Map(document.getElementById('map'), {
      center: { lat: -34.391911, lng: -58.661421 },
      zoom: 12,
    });
    setMap(mapInstance);

    const polylineInstance = new window.google.maps.Polyline({
      path: [],
      geodesic: true,
      strokeColor: polylineColor,
      strokeOpacity: 1.0,
      strokeWeight: 2,
    });

    polylineInstance.setMap(mapInstance);
    setPolyline(polylineInstance);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
  
      reader.onload = (event) => {
        try {
          const data = new Uint8Array(event.target.result);
          const workbook = XLSX.read(data, { type: 'array' });
          const sheetName = workbook.SheetNames[0];
          const sheet = workbook.Sheets[sheetName];
  
          if (!sheet) {
            throw new Error('No se pudo leer la hoja del archivo');
          }
  
          const json = XLSX.utils.sheet_to_json(sheet);
  
          if (json.length === 0) {
            throw new Error('La hoja está vacía');
          }
  
          setData(json);
          setExcelCargado(true);
  
          const path = json.map(point => ({
            lat: parseFloat(point.lat),
            lng: parseFloat(point.lng),
          }));
  
          // Solo actualiza el Polyline aquí, no durante el guardado
          if (polyline) {
            polyline.setPath(path);
          }
  
          setAlertMessage('Archivo Excel cargado');
          setAlertSeverity('success');
          setAlertOpen(true);
        } catch (error) {
          setAlertMessage('Error al procesar el archivo: ' + error.message);
          setAlertSeverity('error');
          setAlertOpen(true);
        }
      };
  
      reader.readAsArrayBuffer(file);
    }
  };
  

  const handleSave = () => {
    if (data.length > 0 && linea) {
      const dataWithLine = data.map(item => ({
        ...item,
        linea: linea,
        color: polylineColor,
      }));
  
      axios.post('https://mapane3.tipicaweb.com/api/api/saveBusData', { data: dataWithLine })
        .then((response) => {
          setAlertSeverity('success');
          setAlertMessage('Archivo guardado exitosamente');
          setAlertOpen(true);
  
          // Limpiar datos y resetear inputs
          setData([]); // Esto solo limpia los datos, no el Polyline
          setLinea('');
          setExcelCargado(false);
  
          // Opcional: Limpiar el Polyline
          if (polyline) {
            polyline.setPath([]);
          }
        })
        .catch((error) => {
          setAlertSeverity('error');
          setAlertMessage('Error al guardar archivo');
          setAlertOpen(true);
        });
    } else {
      setAlertSeverity('error');
      setAlertMessage('No hay datos para guardar o no se ingresó la línea');
      setAlertOpen(true);
    }
  };
  

  const handleCloseAlert = () => {
    setAlertOpen(false);
  };

  const handleColorChange = (color) => {
    setPolylineColor(color);
    if (polyline) {
      polyline.setOptions({ strokeColor: color });
    }
  };

  return (
    <Box id="recorrido-buses-container2">
      <Box className="file-controls2">
        <Button
          variant="contained"
          component="label"
          startIcon={<CloudUploadIcon />}
          id="excel-upload-button2"
        >
          Cargar Excel
          <input type="file" hidden onChange={handleFileChange} />
        </Button>

        {excelCargado && (
          <Box display="flex" alignItems="center" className="success-container2">
            <CheckCircleIcon color="success" className="success-icon2" />
            <span className="success-text2">Archivo Excel cargado</span>
          </Box>
        )}

        <TextField
          className="custom-inpu2"
          label="Línea"
          variant="outlined"
          value={linea}
          onChange={(e) => setLinea(e.target.value)}
        />
        <ColorPicker selectedColor={polylineColor} onChange={handleColorChange} />
        <Button
          variant="contained"
          startIcon={<SaveIcon />}
          onClick={handleSave}
          id="save-button2"
        >
          Guardar
        </Button>
      </Box>
      <Box mt={4}>
        <div id="map" className="map2"></div>
      </Box>

      <Snackbar open={alertOpen} autoHideDuration={6000} onClose={handleCloseAlert}>
        <Alert onClose={handleCloseAlert} severity={alertSeverity} className="custom-alert2">
          {alertMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default RecorridoDeBuses;
