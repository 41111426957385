import React, { useState, useEffect } from 'react';
import {
  Autocomplete,Button,TextField, Box, List, ListItem, ListItemText,IconButton,Snackbar, Alert,
   Grid, Dialog,DialogActions,DialogContent,DialogTitle
} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import axios from 'axios';
import './mymaps.css';
import BusCheckboxes from './BusCheckboxes';

const Mymaps = () => {
  const [buses, setBuses] = useState([]);
  const [puntosInteres, setPuntosInteres] = useState([]);
  const [poligonos, setPoligonos] = useState([]);
  const [selectedElements, setSelectedElements] = useState([]);
  const [filters, setFilters] = useState({
    punto: '',
    linea: '',
    poligono: '',
  });

  const [map, setMap] = useState(null);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertSeverity, setAlertSeverity] = useState('success');
  const [saveMapOpen, setSaveMapOpen] = useState(false); // Para el estado del diálogo de guardar mapa
  const [mapName, setMapName] = useState(''); // Para el nombre del mapa

  useEffect(() => {
    if (window.google) {
      initMap();
    } else {
      const script = document.createElement('script');
      script.src = "https://maps.googleapis.com/maps/api/js?key=AIzaSyD1Nl9yulU0jgl6VRGkK89neIalMrblrws&callback=initMap";
      script.async = true;
      document.body.appendChild(script);
      window.initMap = initMap;
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (filters.punto) {
        const response = await axios.get(`https://mapane3.tipicaweb.com/api/api/getPuntosInteres/${filters.punto}`);
        const data = response.data;
        const groupedData = data.reduce((acc, punto) => {
          if (!acc[punto.nombre]) {
            acc[punto.nombre] = [];
          }
          acc[punto.nombre].push(punto);
          return acc;
        }, {});
        setPuntosInteres(groupedData);
      } else {
        setPuntosInteres([]);
      }

      if (filters.poligono) {
        const response = await axios.get(`https://mapane3.tipicaweb.com/api/api/getPolygonPoints/${filters.poligono}`);
        const data = response.data;
        const groupedData = data.reduce((acc, poligono) => {
          if (!acc[poligono.nombre_zona]) {
            acc[poligono.nombre_zona] = [];
          }
          acc[poligono.nombre_zona].push(poligono);
          return acc;
        }, {});
        setPoligonos(groupedData);
      } else {
        setPoligonos([]);
      }
    };

    fetchData();
  }, [filters]);

  const initMap = () => {
    const mapInstance = new window.google.maps.Map(document.getElementById('map'), {
      center: { lat: -34.391911, lng: -58.661421 },
      zoom: 12,
    });
    setMap(mapInstance);
  };

  const handleSelectElement = (nombre, data, type) => { 
    setSelectedElements(prevElements => {
      const existingElement = prevElements.find(el => el.nombre === nombre && el.type === type);
      
      if (existingElement) {
        // Si el elemento ya existe, lo eliminamos (deseleccionamos)
        handleRemoveElement(nombre);  // Llama a la función para eliminar el polyline
        return prevElements.filter(el => el.nombre !== nombre); // Remueve de la lista
      } else {
        // Añadir el nuevo elemento sin interactuar con el mapa aún
        const newElement = { nombre, data, type };
        return [...prevElements, newElement];
      }
    });
};

  
  
useEffect(() => {
  if (map) {
    selectedElements.forEach(element => {
      // Manejo de marcadores
      if (!element.marker && element.type === 'punto') {
        const markers = element.data.map(punto => {
          const marker = new window.google.maps.Marker({
            position: { lat: parseFloat(punto.lat), lng: parseFloat(punto.lng) },
            map,
            title: punto.nombre,
            icon: punto.icono || null,
          });
          return marker;
        });
        element.marker = markers; // Guardar los marcadores en el elemento
      }
      // Manejo de polilíneas
      else if (element.type === 'bus') {
        console.log('Procesando buses (element.data):', element.data);

        // Agrupar datos por línea para crear polilíneas separadas
        const lines = {};

        element.data.forEach(bus => {
          const lineName = bus.linea;
          const color = bus.color || '#FF0000'; // Color por defecto

          if (!lines[lineName]) {
            lines[lineName] = {
              path: [],
              color: color,
            };
          }

          // Agregar la coordenada a la línea correspondiente
          lines[lineName].path.push({
            lat: parseFloat(bus.lat),
            lng: parseFloat(bus.lng),
          });
        });

        // Crear y asignar una polilínea para cada línea si no existe ya
        Object.keys(lines).forEach(lineName => {
          const existingPolyline = element.polyline?.find(poly => poly.name === lineName);

          if (!existingPolyline) {
            const newPolyline = new window.google.maps.Polyline({
              path: lines[lineName].path,
              geodesic: true,
              strokeColor: lines[lineName].color,
              strokeOpacity: 1.0,
              strokeWeight: 2,
              map,
            });

            newPolyline.name = lineName; // Asignar nombre para identificarla
            element.polyline = [...(element.polyline || []), newPolyline];
          }
        });
      }
      // Manejo de polígonos
      else if (!element.polygon && element.type === 'polygon') {
        const polygonPath = element.data.map(p => ({
          lat: parseFloat(p.lat),
          lng: parseFloat(p.lng),
        }));
        const polygon = new window.google.maps.Polygon({
          paths: polygonPath,
          strokeColor: element.data[0]?.color || '#FF0000', // Color de polígono
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: element.data[0]?.color || '#FF0000', // Color de relleno
          fillOpacity: 0.35,
          map,
        });
        element.polygon = polygon;
      }
    });
  }
}, [selectedElements, map]);

const handleRemoveElement = (nombre) => {
  setSelectedElements(prev => {
    // Primero, eliminamos los elementos del mapa
    prev.forEach(element => {
      if (element.nombre === nombre) {
        if (element.polyline) {
          // Eliminar cada instancia de la polilínea del mapa
          if (Array.isArray(element.polyline)) {
            element.polyline.forEach(poly => poly.setMap(null));
          } else {
            element.polyline.setMap(null);
          }
          console.log(`Polyline de "${nombre}" eliminada del mapa.`);
        }
        if (element.polygon) {
          element.polygon.setMap(null);
          console.log(`Polígono de "${nombre}" eliminado del mapa.`);
        }
        if (element.marker) {
          if (Array.isArray(element.marker)) {
            element.marker.forEach(marker => marker.setMap(null));
          } else {
            element.marker.setMap(null);
          }
          console.log(`Marcadores de "${nombre}" eliminados del mapa.`);
        }
      }
    });

    // Luego, actualizamos la lista de elementos seleccionados
    const updatedElements = prev.filter(el => el.nombre !== nombre);

    // Mostrar mensaje de éxito
    setAlertMessage(`Elemento "${nombre}" eliminado del mapa.`);
    setAlertSeverity('info');
    setAlertOpen(true);

    return updatedElements;
  });
};

  


  const openSaveMapPopup = () => {
    setSaveMapOpen(true);
  };

  const closeSaveMapPopup = () => {
    setSaveMapOpen(false);
  };

  const handleSaveMap = () => {
    const mapData = selectedElements.map(el => ({
      nombre: el.nombre,
      type: el.type,
      data: el.data,
    }));
  
    axios.post('https://mapane3.tipicaweb.com/api/api/saveMap', { name: mapName, data: mapData })
      .then(response => {
        setAlertMessage('Mapa guardado exitosamente');
        setAlertSeverity('success');
        setAlertOpen(true);
        closeSaveMapPopup();
        setMapName(''); // Reiniciar el nombre del mapa
  
        // Limpiar los elementos seleccionados y los filtros
        setSelectedElements([]);
        setFilters({
          punto: '',
          linea: '',
          poligono: '',
        });
      })
      .catch(error => {
        setAlertMessage('Error al guardar el mapa');
        setAlertSeverity('error');
        setAlertOpen(true);
      });
  };
  
  
  

  

  return (
    <Box className="container">
      <Box className="filters-container" display="flex" justifyContent="space-between" mb={2}>
        
        {/* Autocomplete para Puntos de Interés */}
        <Box className="filter">
          <Autocomplete
            className="custom-autocomplete"
            options={Object.keys(puntosInteres).filter(nombre => 
              !selectedElements.some(el => el.nombre === nombre && el.type === 'punto') // Filtrar elementos ya seleccionados
            )}
            getOptionLabel={(option) => option}
            onInputChange={(event, newValue) => setFilters({ ...filters, punto: newValue })}
            onChange={(event, newValue) => {
              if (newValue) {
                handleSelectElement(newValue, puntosInteres[newValue], 'punto');
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Puntos de Interés"
                variant="outlined"
                fullWidth
              />
            )}
          />
        </Box>
  
        {/* Checkbox para Recorridos de Buses */}
      <Box className="filter">
        <BusCheckboxes selectedElements={selectedElements} handleSelectElement={handleSelectElement}
        handleRemoveElement={handleRemoveElement}
        />
      </Box>
  
        {/* Autocomplete para Polígonos */}
        <Box className="filter">
          <Autocomplete
            className="custom-autocomplete"
            options={Object.keys(poligonos).filter(nombre => 
              !selectedElements.some(el => el.nombre === nombre && el.type === 'polygon') // Filtrar elementos ya seleccionados
            )}
            getOptionLabel={(option) => option}
            onInputChange={(event, newValue) => setFilters({ ...filters, poligono: newValue })}
            onChange={(event, newValue) => {
              if (newValue) {
                handleSelectElement(newValue, poligonos[newValue], 'polygon');
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Polígonos"
                variant="outlined"
                fullWidth
              />
            )}
          />
        </Box>
      </Box>
  
      <Grid container spacing={2} mt={2}>
        <Grid item xs={4}>
          <Box>
            <h3>Puntos de Interés</h3>
            <List>
              {selectedElements
                .filter(el => el.type === 'punto')
                .map(element => (
                  <ListItem key={element.nombre}>
                    <ListItemText primary={element.nombre} />
                    <IconButton edge="end" aria-label="delete" onClick={() => handleRemoveElement(element.nombre)}>
                      <DeleteIcon />
                    </IconButton>
                  </ListItem>
              ))}
            </List>
          </Box>
        </Grid>
        
        
        <Grid item xs={4}>
          <Box>
            <h3>Polígonos</h3>
            <List>
              {selectedElements
                .filter(el => el.type === 'polygon')
                .map(element => (
                  <ListItem key={element.nombre}>
                    <ListItemText primary={element.nombre} />
                    <IconButton edge="end" aria-label="delete" onClick={() => handleRemoveElement(element.nombre)}>
                      <DeleteIcon />
                    </IconButton>
                  </ListItem>
              ))}
            </List>
          </Box>
        </Grid>
      </Grid>

      
      <Button
        id="save-map-button"
        variant="contained"
        startIcon={<SaveIcon />}
        onClick={openSaveMapPopup}
      >
        Guardar Mapa
      </Button>

      <Dialog open={saveMapOpen} onClose={closeSaveMapPopup}>
        <DialogTitle>Guardar Mapa</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Nombre del Mapa"
            fullWidth
            value={mapName}
            onChange={(e) => setMapName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={closeSaveMapPopup}>Cancelar</Button>
          <Button onClick={handleSaveMap}>Guardar</Button>
        </DialogActions>
      </Dialog>

      <Snackbar open={alertOpen} autoHideDuration={6000} onClose={() => setAlertOpen(false)}>
        <Alert onClose={() => setAlertOpen(false)} severity={alertSeverity} sx={{ width: '100%' }}>
          {alertMessage}
        </Alert>
      </Snackbar>

      {/* Mapa */}
      <Box id="map" className="map-container" />
    </Box>
  );
};
export default Mymaps;