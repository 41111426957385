import React, { useState, useEffect } from 'react';
import { Button, TextField, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom'; // Importa useNavigate
import axios from 'axios';
import MapWithMarkers from '../../commons/MapWithMarkers';
import * as XLSX from 'xlsx'; // Importa la librería XLSX
import styles from './editarMarcadores.module.css';
import IconSelector from '../../commons/IconSelector';
import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn'; // Ícono de volver
import SaveIcon from '@mui/icons-material/Save';
import FileDownloadIcon from '@mui/icons-material/FileDownload';


const EditarMarcadores = () => {
    const { nombre } = useParams();
    const [marcadores, setMarcadores] = useState([]);
    const [direccion, setDireccion] = useState('');
    const [localidad, setLocalidad] = useState('');
    const [icono, setIcono] = useState('');
    const [dialogOpen, setDialogOpen] = useState(false);

    const navigate = useNavigate(); // Inicializa useNavigate para manejar la navegación

    useEffect(() => {
        const fetchMarcadores = async () => {
            try {
                const response = await fetch(`https://mapane3.tipicaweb.com/api/api/getPuntosInteresPorNombre/${nombre}`);
                const data = await response.json();

                // Asigna el icono por defecto a los marcadores que no tienen icono
                const marcadoresConIcono = data.map(marcador => ({
                    ...marcador,
                    icono: marcador.icono || undefined // Icono por defecto
                }));

                setMarcadores(marcadoresConIcono);
            } catch (error) {
                console.error('Error fetching marcadores:', error);
            }
        };
        fetchMarcadores();
    }, [nombre]);

    const handleEdit = async () => {
        try {
            const response = await axios.put('https://mapane3.tipicaweb.com/api/api/updatePuntoInteres', {
                direccion,
                localidad,
                nombre,
                icono,
                action: 'edit',
            });
            alert('Marcador editado correctamente');
        } catch (error) {
            console.error('Error al editar marcador:', error);
        }
    };

    const handleIconSelection = (icon) => {
        setIcono(icon);
        setDialogOpen(false);
    };

    const handleAddMarker = async () => {
        if (direccion && localidad) {
            try {
                const response = await axios.put('https://mapane3.tipicaweb.com/api/api/updatePuntoInteres', {
                    direccion,
                    localidad,
                    nombre,
                    icono,
                    action: 'add',
                });
                alert('Marcador añadido correctamente');
                setMarcadores([...marcadores, { direccion, localidad, icono }]);
                setDireccion('');
                setLocalidad('');
                window.location.reload();
            } catch (error) {
                console.error('Error al añadir marcador:', error);
            }
        } else {
            alert('Por favor, ingrese una dirección y localidad');
        }
    };

    const handleMarkerClick = async (id) => {
        if (window.confirm('¿Estás seguro de que deseas eliminar este marcador?')) {
            try {
                await axios.put('https://mapane3.tipicaweb.com/api/api/updatePuntoInteres', {
                    id,
                    action: 'delete',
                });

                setMarcadores((prevMarcadores) => prevMarcadores.filter((marker) => marker.id !== id));
                alert('Marcador eliminado correctamente');
            } catch (error) {
                console.error('Error al eliminar marcador:', error);
                alert('Hubo un error al eliminar el marcador');
            }
        }
    };

    // Función para manejar el botón de "Volver"
    const handleGoBack = () => {
        navigate(-1); // Esto navegará a la página anterior en el historial
    };

    // Función para exportar datos a Excel
    const exportToExcel = () => {
        const filteredData = marcadores.map(({ nombre, direccion, localidad }) => ({
            Nombre: nombre,
            direccion: direccion,
            localidad: localidad,
        }));

        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.json_to_sheet(filteredData);
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Marcadores');
        XLSX.writeFile(workbook, `Marcadores_${nombre}.xlsx`);
    };

    return (
        <div className={styles.container}>
            <div className={styles.headerRow}>
                <Button
                    variant="contained"
                    onClick={handleGoBack}
                    startIcon={<KeyboardReturnIcon />}
                    className={styles.backButton}
                >
                    Volver
                </Button>
                <h2>Editar Marcadores de {nombre}</h2>
    
                {/* Contenedor para apilar los botones a la derecha */}
                <div className={styles.buttonContainer}>
                    <Button
                        variant="contained"
                        onClick={handleEdit}
                        startIcon={<SaveIcon />} 
                        className={styles.saveButton}
                    >
                        Guardar Cambios
                    </Button>
                    <Button
                        variant="contained"
                        onClick={exportToExcel}
                        startIcon={<FileDownloadIcon />} 
                        className={styles.exportButton}
                    >
                        Exportar Excel
                    </Button>
                </div>
            </div>
    
            {/* Inputs y botones ahora directamente en el contenedor */}
            <div className={styles.inputGroup}>
                <TextField
                    id="direccion-input"
                    label="Dirección"
                    value={direccion}
                    onChange={(e) => setDireccion(e.target.value)}
                    className={styles.inputField}
                />
                <TextField
                    id="localidad-input"
                    label="Localidad"
                    value={localidad}
                    onChange={(e) => setLocalidad(e.target.value)}
                    className={styles.inputField}
                />
            </div>
    
            {/* Grupo de botones */}
            <div className={styles.buttonGroup}>
                <Button
                    variant="contained"
                    onClick={() => setDialogOpen(true)}
                    className={styles.iconButton}
                >
                    Seleccionar Ícono
                </Button>
                <Button
                    variant="contained"
                    onClick={handleAddMarker}
                    startIcon={<AddLocationAltIcon />}
                    className={styles.addButton}
                >
                    Añadir Marcador
                </Button>
            </div>
    
            {/* Mapa con los marcadores */}
            <MapWithMarkers
                id="edit-map"
                data={marcadores}
                iconoSeleccionado={icono}
                onMarkerClick={handleMarkerClick}
            />
    
            {/* Diálogo para seleccionar ícono */}
            <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
                <DialogTitle>Seleccionar Ícono</DialogTitle>
                <DialogContent>
                    <IconSelector onSelectIcon={handleIconSelection} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDialogOpen(false)}>Cerrar</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
    
};

export default EditarMarcadores;
